import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type CitiesArray = {
  name: string,
  id: number,
  guid: string,
  clubs: ClubsArray[],
}

type ClubsArray = {
  name: string,
  id: number,
  guid: string,
  address: string,
}

type PersonnelArray = {
  id: number,
  firstName: string,
  surname: string,
  hrefMainPhoto: string,
  personnelGuid: string,
  emptyPhoto: boolean,
}

type ZonesArray = {
  id: number,
  name: string,
  guid: string,
}

type ArraysState = {
  cities: CitiesArray[],
  clubs: ClubsArray[],
  personnel: PersonnelArray[],
  filteredPers: PersonnelArray[],
  zones: ZonesArray[],
}

const initialState: ArraysState = {
  
  cities: [
    {    
      name: '',
      id: 0,
      guid: '',
      clubs: [
        {
          name:'',
          id: 0,
          guid: '',
          address: '',
        },
      ], 
    },
  ], 

  clubs: [
    {
      name:'',
      id: 0,
      guid: '',
      address: '',
    },
  ], 

  personnel: [
    {
      id: 0,
      firstName: '',
      surname: '',
      hrefMainPhoto: '',
      personnelGuid: '',
      emptyPhoto: true,
    },
  ],

  filteredPers: [
    {
      id: 0,
      firstName: '',
      surname: '',
      hrefMainPhoto: '',
      personnelGuid: '',
      emptyPhoto: true,
    },
  ],

  zones: [
    {
      id: 0,
      name: '',
      guid: '',
    },
  ]
}

const arraysSlice = createSlice({
  name: 'arrays',
  initialState,
  reducers: {
    updateArrayCity(state, action: PayloadAction<CitiesArray[]>) {
      state.cities = action.payload
    },
    updateArrayClub(state, action: PayloadAction<ClubsArray[]>) {
      state.clubs = action.payload
    },
    updateArrayPersonnel(state, action: PayloadAction<PersonnelArray[]>) {
      state.personnel = action.payload
    },
    updateArrayFilteredPers(state, action: PayloadAction<PersonnelArray[]>) {
      state.filteredPers = action.payload
    },
    updateArrayZones(state, action: PayloadAction<ZonesArray[]>) {
      state.zones = action.payload
    },
    clearArrayPersonnel(state) {
      state.personnel = [
        {
          id: 0,
          firstName: '',
          surname: '',
          hrefMainPhoto: '',
          personnelGuid: '',
          emptyPhoto: true,
        },
      ]
    },
    clearZonesArray(state) {
      state.zones = [
        {
          id: 0,
          name: '',
          guid: '',
        },
      ]
    }
  }
})

export const {
  updateArrayCity,
  updateArrayClub, 
  updateArrayPersonnel,
  updateArrayFilteredPers,
  updateArrayZones,
  clearArrayPersonnel,
  clearZonesArray,
} = arraysSlice.actions
export default arraysSlice.reducer


import { configureStore } from '@reduxjs/toolkit'
import choicenReducer from './choicenSlice'
import arraysReducer from './arraysSlice'

const store = configureStore({
  reducer: {
    choicen: choicenReducer,
    arrays: arraysReducer
  }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
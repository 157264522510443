import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type CityItem = {
  name: string,
  id: number,
  guid: string,
}

type ClubItem = {
  name: string,
  id: number,
  guid: string,
}

type ZoneItem = {
  name: string,
  id: number,
  guid: string,
}

type TrainerItem = {
  id: number,
}

type choicenState = {
  city: CityItem,
  club: ClubItem,
  zone: ZoneItem,
  trainer: TrainerItem,
}

const initialState: choicenState = {
  city: {name: 'Не выбрано', id: 0, guid: ''},
  club: {name: 'Не выбрано', id: 0, guid: ''},
  zone: {name: 'Любая', id: 0, guid: ''},
  trainer: {id: 0},
}

const choicenSlice = createSlice({
  name: 'choicen',
  initialState,
  reducers: {
    updateChoicenCity(state, action: PayloadAction<CityItem>) {
      state.city = action.payload
    },
    updateChoicenClub(state, action: PayloadAction<ClubItem>) {
      state.club = action.payload
    },
    updateChoicenZone(state, action: PayloadAction<ClubItem>) {
      state.zone = action.payload
    },
    clearChoicenZone(state) {
      //state.city = {name: 'Не выбрано', id: 0, guid: ''}
      //state.club = {name: 'Не выбрано', id: 0, guid: ''}
      state.zone = {name: 'Любая', id: 0, guid: ''}
    },
  }
})

export const {
  updateChoicenCity, 
  updateChoicenClub,
  updateChoicenZone,
  clearChoicenZone,
} = choicenSlice.actions
export default choicenSlice.reducer
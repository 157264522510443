import React, {useEffect, useState} from "react";
import {getGalleryImage, getPersonnelProfile, getTrainerById} from "../../api/get";
import { useAppSelector } from "../../hook";
import { useQuery } from "../../hooks/useQuery";
import './TrainerInfo.scss'

interface TrainerInfoProps {
  id: number;
}

export const TrainerInfo: React.FC<TrainerInfoProps> = () => {
  const coachInit = {
    surname: 'Фамилия',
    firstName: 'Имя',
    patronymic: 'Отчество',
    qualifications: 'Категория тренера',
    achievements: [''],
    education: [''],
    storesAddress: '',
    storesName: '',
  }
  const [coach, setCoach] = useState(coachInit)
  const [personPhoto, setPersonPhoto] = useState('')

  const choicen = useAppSelector(state => state.choicen)
  const hrefMainPhoto = '/api/photos/7921f4df-bbd8-4ba7-b1ec-aff5e5192cf5'

  const params = useQuery()
  const trainerId = params.get('trainerId')
  const id = ()=> {
    if(trainerId){
      return +trainerId
    } else if (choicen.trainer.id > 0) {
      return choicen.trainer.id
    }
    return 705
  }

  useEffect( () => {
    (async function () {    
      const { data: profile } = await getPersonnelProfile(id())
      console.log('got profile', profile)

      let qualificationsArr: string[] = []
      if(profile.qualifications.length){
        profile.qualifications.map(item => {
          qualificationsArr.push(item.name)
        })
      }
      const qualifications = qualificationsArr.join(' / ')

      const obj = {
        surname: profile.surname,
        firstName: profile.firstName, 
        patronymic: profile.patronymic,
        qualifications: qualifications,
        achievements: profile.achievements? profile.achievements.split('\n') : [],
        education: profile.education? profile.education.split('\n') : [],
        storesAddress: profile.stores[0]? profile.stores[0].address : '',
        storesName: profile.stores[0]? profile.stores[0].name : '',
      }
      console.log('obj', obj)
      setCoach(obj)

    })()
  },[]);

  useEffect(() => {
    (async function(){
      const { data: trainer } = await getTrainerById(id())
      console.log('getTrainerById = ', trainer)

      // const { data: blob } = await getGalleryImage(hrefMainPhoto);
      // let reader = new window.FileReader()
      // reader.readAsDataURL(blob)
      // reader.onload = function () {
      //   let imageDataUrl = reader.result
      //   if(typeof imageDataUrl === 'string') {
      //     setPersonPhoto(imageDataUrl)
      //   }
      // }
    })();
  }, [coach])

  return (
    <div className="TrainerInfo">
      <div className="TrainerInfo_page">
        <div className="TrainerInfo_page_topic">
          <div className="TrainerInfo_page_topic_circle">
            <div className="TrainerInfo_page_topic_circle_arrow"></div>
          </div>
          <div className="TrainerInfo_page_topic_text">К специалистам</div>
        </div>
        <div className="TrainerInfo_page_container">
          <div className="TrainerInfo_page_container_side">
            <div className="TrainerInfo_page_container_side_photo">
            <img src={personPhoto} />
            </div>
          </div>
          <div className="TrainerInfo_page_container_main">
            <div className="TrainerInfo_page_container_main_name">{coach.surname}<br/>{coach.firstName}</div>
            <div className="TrainerInfo_page_container_main_info">{coach.qualifications}</div>
            <div className="TrainerInfo_page_container_main_buttons">
              <div className="TrainerInfo_page_container_main_buttons_record">Записаться</div>
              <div className="TrainerInfo_page_container_main_buttons_recall">Написать отзыв</div>
            </div>
            <div className="TrainerInfo_page_container_main_box">
              {/* <div className="TrainerInfo_page_container_main_box_item">
                <div className="TrainerInfo_page_container_main_box_item_header">Зоны</div>
                <div className="TrainerInfo_page_container_main_box_item_text">Тренажерный зал/Мастер тренер</div>
              </div> */}

              <div className="TrainerInfo_page_container_main_box_item">
                <div className="TrainerInfo_page_container_main_box_item_header">Оказывает услуги</div>
                <div
                  className="TrainerInfo_page_container_main_box_item_text">
                  {coach.storesAddress}
                </div>
                <div
                  className="TrainerInfo_page_container_main_box_item_text">
                  {coach.storesName}
                </div>
              </div>
              {coach.achievements.length?
                <div className="TrainerInfo_page_container_main_box_item">
                  <div className="TrainerInfo_page_container_main_box_item_header">Достижения</div>
                  {coach.achievements.map((item, index) => {
                    return(
                      <div
                        className="TrainerInfo_page_container_main_box_item_text"
                        key={index}>
                        {item}
                      </div>
                    )
                  })}
                </div> : false
              }

              {coach.education.length?
                <div className="TrainerInfo_page_container_main_box_item">
                  <div className="TrainerInfo_page_container_main_box_item_header">Образование</div>
                  {coach.education.map((item, index) => {
                    return(
                      <div
                        className="TrainerInfo_page_container_main_box_item_text"
                        key={index}>
                        {item}
                      </div>
                    )
                  })}
                </div> :false
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { clearChoicenZone } from '../../store/choicenSlice';
import { FilterMain } from '../FilterMain/FilterMain'
import './Modal.scss'

interface Modal {
  openedModule: string
  openCloseModule: Function;
  setIsModalOpen: Function;
}

export const Modal: React.FC<Modal> = (props) => {
  const {
    openedModule = '',
    openCloseModule,
    setIsModalOpen,
  } = props

  const dispatch = useDispatch()

// useEffect(() =>{
//   window.addEventListener('scroll', (e) => {
//     console.log('addEvent')
//     window.scrollTo(0,0);
  
//   })
//   return () => {
//     window.removeEventListener('scroll', (e) => {
//       window.scrollTo(0,0);
//       console.log('removeEvent')
//     })
//   }
// }, [])

const clear = () => {
  dispatch(clearChoicenZone())
  setIsModalOpen(false)
}

  return (
    <div className='Modal'>
      <div className='Modal_background'></div>
      <div className='Modal_button-cancel'
        onClick={() => setIsModalOpen(false)}>
        <div className='Modal_button-cancel_bg1'></div>
        <div className='Modal_button-cancel_bg2'></div>
      </div>
      <div className='Modal_board'>
        <div className='Modal_board_header'>Фильтры</div>
        <FilterMain 
          openedModule={openedModule}
          openCloseModule={openCloseModule}
        />
      <div className='Modal_board_btns'>
        <button className='Modal_board_btns_cancel'
          onClick={() => clear()}>
          <span>Отмена</span>
        </button>
        <button className='Modal_board_btns_submit'
          onClick={() => setIsModalOpen(false)}
          ><span>Применить</span></button>
      </div>
      </div>
    </div>
  )
}
import React from 'react'
import {useAppSelector} from '../../hook'
import {
  updateChoicenCity, 
  updateChoicenClub,
  updateChoicenZone,
} from '../../store/choicenSlice'
import {FilterModule} from '../FilterModule/FilterModule'
import './FilterMain.scss'

interface FilterMainProps {
  openedModule: string
  openCloseModule: Function  
}

export const FilterMain: React.FC<FilterMainProps> = (props) => {
  const {
    openedModule = '',
    openCloseModule,
  } = props

  const choicen = useAppSelector(state => state.choicen)
  const citiesArr = useAppSelector(state => state.arrays.cities)
  const clubsArr = useAppSelector(state => state.arrays.clubs)
  const zonesArr = useAppSelector(state => state.arrays.zones)

  return (
    <div className='Filter'>
      <div className='Filter_item'
        onClick={() => openCloseModule('citi')}>
          <div className='Filter_item_topic'>Город</div>
          <div className='Filter_item_text'>{choicen.city.name}</div>
          <div className='Filter_item_triangle'></div>
          {openedModule === 'citi' ? <FilterModule 
            choicen={choicen.city.name}
            itemArr={citiesArr}
            updateChoicenItem={updateChoicenCity}
            /> : false}
      </div>
      <div className='Filter_item'
        onClick={() => openCloseModule('club')}>
        <div className='Filter_item_topic'>Клуб</div>
        <div className='Filter_item_text'>{choicen.club.name}</div>
        <div className='Filter_item_triangle'></div>
          {openedModule === 'club' ? <FilterModule 
            choicen={choicen.club.name}
            itemArr={clubsArr}
            updateChoicenItem={updateChoicenClub}
            /> : false}
      </div>
      <div className='Filter_item'
        onClick={()=> openCloseModule('zone')}>
        <div className='Filter_item_topic'>Зона</div>
        <div className='Filter_item_text'>{choicen.zone.name}</div>
        <div className='Filter_item_triangle'></div>
        {openedModule === 'zone' ? <FilterModule 
            choicen={choicen.zone.name}
            itemArr={zonesArr}
            updateChoicenItem={updateChoicenZone}
            /> : false}
      </div>
      <div className='Filter_item'>
        <div className='Filter_item_topic'>Квалификация</div>
        <div className='Filter_item_text'>Любая</div>
        <div className='Filter_item_triangle'></div>
      </div>
    </div>
  )
}

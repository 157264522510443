import React, {useEffect} from 'react'
import {useAppDispatch} from './hook'
import {updateArrayCity} from './store/arraysSlice'

import {RequestAxios} from "./api"
import {getCitiesList, getClubsList} from "./api/get"
import { CitiesList } from './api/get/getCitiesList/interfaces'

import {Trainers} from './components/Trainers/Trainers'
import { TrainerInfo } from './components/TrainerInfo/TrainerInfo'
import './App.css';

import {calcHeight, messageService, useMessageInit, useMutationObserver} from "./handlers/messages";

import { BrowserRouter } from 'react-router-dom'

type CytiesArray = {
  name: string,
  id: number,
  guid: string,
  clubs: ClubsArray[],
}

type ClubsArray = {
  name: string,
  id: number,
  guid: string,
  address: string,
}
function App() {
  const dispatch = useAppDispatch()
  useEffect( () => {
      (async function () {
          await RequestAxios.login()

          const { data: cities } = await getCitiesList()
          console.log('got cities', cities)

          async function getAsyncClubs(data: CitiesList[]){
            const result: CytiesArray[] = []

            for(const item of data) {
              if(item.localityName) {

                const { data: clubs } = await getClubsList(item.localityName)
                const clubsArr: ClubsArray[] = [
                  {
                    name:'Не выбрано',
                    id: 0,
                    guid: '',
                    address: '',
                  }
                ]
                clubs.map((clubsItem) => {
                  if(clubsItem.name) {
                    clubsArr.push({
                      name: clubsItem.name,
                      id: clubsItem.id,
                      guid: clubsItem.storeGuid,
                      address: clubsItem.address,
                    })
                  }
                })

                result.push({
                  name: item.localityName,
                  id: item.storeCount,
                  guid: '',
                  clubs: clubsArr,
                })
              }
            }
            return result
          }

          const citiesArr: CytiesArray[] = await getAsyncClubs(cities)
          dispatch(updateArrayCity(citiesArr))
      })()
  },[]);

    const handleMutation = () => {
        messageService.sendMessage('SITE_RESIZE', calcHeight(messageService.$container))
    }
    const { containerRef } = useMutationObserver<HTMLDivElement>(handleMutation)
    useMessageInit(containerRef)

  return (
    <div ref={containerRef}>
        <BrowserRouter>
        <Trainers/>
        <TrainerInfo id={685}/>
        </BrowserRouter>
    </div>
  );
}

export default App;

import React, {useEffect, useState}  from 'react'
import emptyIMG from '../../images/empty.png'
import './PhotoCard.scss'
import {getGalleryImage} from "../../api/get";

interface PhotoCardProps {
  emptyPhoto: boolean
  firstName: string
  surname: string
  hrefMainPhoto: string
}

export const PhotoCard: React.FC<PhotoCardProps> = (props) => {
  const {
    emptyPhoto,
    firstName,
    surname,
    hrefMainPhoto,
  } = props

  const [personPhoto, setPersonPhoto] = useState('')

  useEffect(() => {
    (async function(){
      if(!emptyPhoto){
        const { data: blob } = await getGalleryImage(hrefMainPhoto);
        let reader = new window.FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function () {
          let imageDataUrl = reader.result
          if(typeof imageDataUrl === 'string') {
            setPersonPhoto(imageDataUrl)
          }
        }
      }
    })();
  }, [])

  return(
    <div className={`Card_${emptyPhoto? 'item-empty': 'item'}`}>
      <img src={personPhoto} />
      <div className={`Card_${emptyPhoto? 'item-empty_text': 'item_text'}`}>
        <span>{firstName}<br/>{surname}</span>
      </div>
    </div>
  )
}
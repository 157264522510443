import React from 'react'
import {useAppDispatch} from '../../hook'
import './FilterModule.scss'

interface FilterModuleProps {
  choicen: string;
  itemArr: Array<{name: string, id: number, guid: string}>;
  updateChoicenItem: Function;
}

export const FilterModule: React.FC<FilterModuleProps> = (props) => {
  const {
    choicen,
    itemArr,
    updateChoicenItem,
  } = props

  const dispatch = useAppDispatch()

  return (
    <>  
      {itemArr.length > 1 ?
        <div className='Filter-module'>
          <div className='Filter-module_list'>
            {itemArr.map((item, index) => {
              return (
                <div 
                  key={index}
                  className={`Filter-module_list_item ${item.name === choicen? 'choicen' : ''}`}
                  onClick={() => dispatch(updateChoicenItem({
                    name: item.name,
                    id: item.id,
                    guid: item.guid,              
                  }))}
                >{item.name}</div>
              )
            })} 
          </div>
        </div>: false //<div className='Filter-module_list_item'>Выберите город</div>
      } 
    </>
  )
}

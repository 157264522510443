import React, {useEffect, useState}  from 'react'
import {useAppSelector, useAppDispatch} from '../../hook'
import { useParams } from 'react-router-dom'
import {
  updateChoicenClub, 
  updateChoicenCity,
  updateChoicenZone,
  clearChoicenZone,
} from '../../store/choicenSlice'
import {
  updateArrayClub,
  updateArrayPersonnel,
  updateArrayFilteredPers,
  clearArrayPersonnel,
  updateArrayZones,
  clearZonesArray,
} from '../../store/arraysSlice'
import {
  getClubsList, 
  getPersonnelList, 
  getZonesOfClubList,
  getPersonnelOfZoneList,
  getPersonnelProfile,
} from "../../api/get";
import {FilterMain} from '../../Modules/FilterMain/FilterMain'
import emptyIMG from '../../images/empty.png'
import { Modal } from '../../Modules/Modal/Modal';
import './Trainers.scss'
import { PhotoCard } from './PhotoCard'
import { useQuery } from '../../hooks/useQuery'

type PersonnelArray = {
  id: number,
  firstName: string,
  surname: string,
  hrefMainPhoto: string,
  personnelGuid: string,
  emptyPhoto: boolean,
}

type ZonesArray = {
  id: number,
  name: string,
  guid: string,
}

export const Trainers: React.FC = () => {
  const dispatch = useAppDispatch()
  const choicen = useAppSelector(state => state.choicen)
  const personArr = useAppSelector(state => state.arrays.personnel)
  const citiesArr = useAppSelector(state => state.arrays.cities)
  const filteredPersonArr = useAppSelector(state => state.arrays.filteredPers)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>()
  const [openedModule, setOpenedModule] = useState<string>('')
  
  const params = useQuery()
  console.log('params = ', params)
  const clubId = params.get('clubId')
  const trainerId = params.get('trainerId')

  useEffect(() => {
    if(clubId && citiesArr.length > 1) {
      let findCity = choicen.city
      let findClub = choicen.club
      const isFindCity = citiesArr.filter((city) => {
        const isFindClub = city.clubs.filter(club => club.id == +clubId)
        if(isFindClub.length > 0) {
          findClub = {
            name: isFindClub[0].name,
            id: isFindClub[0].id,
            guid: isFindClub[0].guid,
          }
          return true
        }
      })
      if(isFindCity.length > 0) {
        findCity = {
          name: isFindCity[0].name,
          id: isFindCity[0].id,
          guid: '',
        }
      }
      dispatch(updateChoicenCity(findCity))
      dispatch(updateChoicenClub(findClub))
    }
  }, [citiesArr])

  useEffect(() => {
    if(choicen.city.id > 0) {
        const clubs = citiesArr.filter(item => item.name === choicen.city.name)[0].clubs 
        dispatch(updateArrayClub(clubs))
    }
  }, [choicen.city])



  useEffect( () => {
    if(choicen.club.id !== 0) {
      (async function () {
        const { data: personnel } = await getPersonnelList(choicen.club.id)
        console.log('got personnel in choicen club', personnel)
        const array: PersonnelArray[] = []
        personnel.map((item) => {
          if(item.hrefMainPhoto) {
              array.push({
                id: item.id,
                firstName: item.firstName,
                surname: item.surname,
                hrefMainPhoto: item.hrefMainPhoto,
                personnelGuid: item.personnelGuid,
                emptyPhoto: false,
              })
          } else {
            array.push({
              id: item.id,
              firstName: item.firstName,
              surname: item.surname,
              hrefMainPhoto: emptyIMG,
              personnelGuid: item.personnelGuid,
              emptyPhoto: true,
            })
          }
        })
        console.log('store: personnel array in choicen club', array)
        dispatch(updateArrayPersonnel(array))
        dispatch(updateArrayFilteredPers(array))

        const { data: zones } = await getZonesOfClubList(choicen.club.guid)
        //console.log('got zones in choicen club', zones)
        const zonesArr: ZonesArray[] = [{id: 0, name: 'Любая', guid: ''}]
        zones.map((item) => {
          zonesArr.push({
            id: item.id,
            name: item.zoneType.name,
            guid: item.zoneType.guid,
          })
        })

        //console.log('store: zones array in choicen club', zonesArr)
        dispatch(updateArrayZones(zonesArr))
      })()
    } else {
      dispatch(updateArrayPersonnel(personArr))
      dispatch(updateArrayFilteredPers(personArr))
    }
    dispatch(clearZonesArray())
    dispatch(updateChoicenZone({name: 'Любая', id: 0, guid: ''}))
  },[choicen.club])

  useEffect( () => {
    if(choicen.zone.id !== 0) {
      (async function () {
        const { data: personnelOfZone } = await getPersonnelOfZoneList(choicen.zone.id)
        console.log('got personnel in choicen zone', personnelOfZone)

        const array: PersonnelArray[] = []

        personnelOfZone.map( item => {
          let result = personArr.find(PersItem => {
            if(PersItem.id === item.id) {
              return true
            }
          }) 
          if(result) {
            array.push(result)
          }
        })

        console.log('store: filtered personnel array in choicen club zone', array)
        dispatch(updateArrayFilteredPers(array))

      })()
    } else {
      dispatch(updateArrayFilteredPers(personArr))
    }
  },[choicen.zone])

 
  function openCloseModule(module: string) { 
    if(openedModule === module){
      setOpenedModule('')      
    } else {
      setOpenedModule(module)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    const mobile = window.matchMedia('screen and (max-width: 719px)').matches
    setIsMobile(mobile)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  const updateSize = () => {
    const mobile = window.matchMedia('screen and (max-width: 719px)').matches

    if(isMobile !== mobile) {
      setIsMobile(mobile)
      setIsModalOpen(false)
    }
  }

  return (
    <div className='Trainers'>
      <div className='Trainers_page'>
        <div className='Trainers_page_topic'>
          <div className='Trainers_page_topic_head'>Тренеры</div>
          <div className='Trainers_page_topic_text'>В нашей команде более 20 сертифицированных профессиональных тренеров, которые помогут тебе стать лучше</div>
        </div>
        <div className='Trainers_page_container'>

          {!isMobile? 
            <FilterMain 
              openedModule={openedModule}
              openCloseModule={openCloseModule}
            /> : 
            <div className='Trainers_page_container_btns'>
              {filteredPersonArr.length == 1 ?
                <button className='Trainers_page_container_btns_open'
                  onClick={() => setIsModalOpen(true)}>
                  <span>Фильтры</span>
                </button>:
                <>
                  <button className='Trainers_page_container_btns_open_gray'
                    onClick={() => setIsModalOpen(true)}>
                    <span>Фильтры ({filteredPersonArr.length})</span>
                  </button>
                  <button className='Trainers_page_container_btns_clear'
                    onClick={() => dispatch(clearChoicenZone())}>
                    <span>Сбросить</span>
                  </button>
                </>
              }
            </div>
          }

          {isModalOpen? 
            <Modal
              openedModule={openedModule}
              openCloseModule={openCloseModule}
              setIsModalOpen={setIsModalOpen}
            /> :
            false 
          }

          <div className='Trainers_page_container_join'>
            <div className='Trainers_page_container_join_img'></div>
            <div className='Trainers_page_container_join_linear'></div>
            <div className='Trainers_page_container_join_box'>
              <div className='Trainers_page_container_join_box_topic'>Присоедняйся<br/>к команде</div>
              <div className='Trainers_page_container_join_box_text'>Тренируй и зарабатывай<br/> и еще немного рыбов</div>
              <button className='Trainers_page_container_join_box_button'>
                <span>Заполнить анкету</span>
              </button>
            </div>
          </div>
          <div className='Trainers_page_container_main'>
            {filteredPersonArr.length > 1 ? 
              (filteredPersonArr.map((item) => {
                return (
                  <PhotoCard 
                    key={item.id}
                    emptyPhoto={item.emptyPhoto}
                    hrefMainPhoto={item.hrefMainPhoto}
                    firstName={item.firstName}
                    surname={item.surname}
                  />
                )
              })) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}
